import { PAGE_TYPE_ENUM } from "@shared/types";
import { DatoComponentsWrapper } from "components/common/DatoComonentsWrappeer/DatoComponentsWrapper";
import PageLayout from "components/layout/PageLayout";
import { useLogContext } from "context/log";
import { generatePageId } from "helpers/analyticsLogger";
import { useRouter } from "next/router";
import { FC, useLayoutEffect, useRef } from "react";
import { LandingPageBySlugQuery } from "services/datocms/generated";

type LandingPageProps = {
  landingPage: NonNullable<LandingPageBySlugQuery["landingPage"]>;
};

const LandingPage: FC<LandingPageProps> = ({ landingPage }) => {
  const router = useRouter();
  const { useLogPageView } = useLogContext();
  const elRef = useRef<HTMLDivElement>(null);
  const { components, content, seo } = landingPage;

  useLayoutEffect(() => {
    const range = document.createRange();
    if (elRef.current && content) {
      range.selectNode(elRef.current);
      const documentFragment = range.createContextualFragment(content);

      // Inject the markup, triggering a re-run!
      elRef.current.innerHTML = "";
      elRef.current.append(documentFragment);
    }
  }, [router.query, content]);

  useLogPageView({
    pageType: PAGE_TYPE_ENUM.other,
    id: generatePageId("landing page"),
  });

  return (
    <PageLayout seo={seo}>
      {content && (
        <div ref={elRef} dangerouslySetInnerHTML={{ __html: content }}></div>
      )}
      <DatoComponentsWrapper components={components} />
    </PageLayout>
  );
};

export default LandingPage;
