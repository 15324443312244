import * as Sentry from "@sentry/nextjs";
import Category from "components/category";
import LandingPage from "components/LandingPage";
import { setCachingHeader } from "helpers/cacheHeader";
import { GetServerSideProps } from "next";
import { FC } from "react";
import { createDatoClient } from "services/datocms";
import {
  ComponentsWrapperFragment,
  DatoProductForCategoryPageFragment,
  getSdk,
  LandingPageBySlugQuery,
  ProductCategoryFragment,
} from "services/datocms/generated";
import { createQueryParamsForCaching } from "utils/create-query-params";

type LandingAndCategoryPageProps = {
  products?: DatoProductForCategoryPageFragment[];
  category?: ProductCategoryFragment;
  landingPage?: LandingPageBySlugQuery["landingPage"];
  components?: ComponentsWrapperFragment | null;
};

export const getServerSideProps: GetServerSideProps<
  LandingAndCategoryPageProps
> = async (context) => {
  setCachingHeader(context);

  const slug = context?.params?.slug || "";

  try {
    // We launch all promises in parallel, and then first await for landingPage, and then for everything else
    const landingPagePromise = getSdk(
      createDatoClient(
        context.preview,
        createQueryParamsForCaching({
          queryType: "LandingPageBySlug",
          queryVariables: { slug },
        }),
      ),
    ).LandingPageBySlug({
      slug: `${slug}`,
    });

    const restOfDataPromise = Promise.all([
      getSdk(
        createDatoClient(
          context.preview,
          createQueryParamsForCaching({
            queryType: "ProductCategoryBySlug",
            queryVariables: { slug: `${slug}` },
          }),
        ),
      ).ProductCategoryBySlug({
        slug: `${slug}`,
      }),
    ]);

    const { landingPage } = await landingPagePromise;

    if (landingPage) {
      return {
        props: {
          landingPage,
        },
      };
    }

    const [{ productCategory }] = await restOfDataPromise;

    if (productCategory) {
      return {
        props: {
          category: productCategory,
          products: productCategory?._allReferencingProducts || [],
          components: productCategory?.components,
        },
      };
    }

    return {
      notFound: true,
    };
  } catch (error) {
    Sentry.captureException(error);
    return {
      notFound: true,
    };
  }
};

const LandingOrCategoryPage: FC<LandingAndCategoryPageProps> = ({
  landingPage,
  products,
  category,
  components,
}) => {
  if (landingPage) {
    return <LandingPage landingPage={landingPage} />;
  }

  // TODO: Currently if the category is bottles, we fetch all compatible machines tied to each bottle
  // Which is fine, BUT we send repeating data to FE, and images take up much space.
  // This causes nextjs to complain: data for page "/[slug]/[id]" (path "/butelki/art_marmur") is 580 kB which exceeds the threshold of 128 kB, this amount of data can reduce performance.
  if (category) {
    return (
      <Category
        category={category}
        products={products}
        components={components as ComponentsWrapperFragment}
      />
    );
  }
};

export default LandingOrCategoryPage;
