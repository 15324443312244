export function isWhite(str: string) {
  const whiteLimit = 200;

  const r = parseInt("0x" + str.substring(1, 3));
  const g = parseInt("0x" + str.substring(3, 5));
  const b = parseInt("0x" + str.substring(5, 7));

  if (r < whiteLimit || b < whiteLimit || g < whiteLimit) {
    return false;
  }
  return true;
}
